import React from "react";

import CarrerList from "../../data/Carrer/index.json";

const Careers = () => {
  let carrerData = CarrerList[0];
  // console.log(carrerData);
  return (
    <>
      <div className="careers-page-wrapper sm-top sm-bottom">
        <div className="container">
          <h2 className="careers-page-title">We are Hiring</h2>
          <p style={{ fontWeight: "bold" }}>
            For further details Email to -{" "}
            <a href="mailto:hr@kangaroocareindia.com">
              hr@kangaroocareindia.com
            </a>{" "}
            or Contact on <a href="tel:9686059747">9686059747</a>
          </p>
          <p></p>
          <p></p>
          <div
            className="service-details-info"
            dangerouslySetInnerHTML={{ __html: carrerData.aboutServiceDesc }}
          />
          {/*
          <div className="row justify-content-around">
            <h4 className="text-center">
              Kangaroo Care is hiring for the following positions
            </h4>
          </div>
          <div className="row sm-top service-details-info">
            <ul>
              <li>Psychotherapists or Psychological Counsellors</li>
              <li>Nurse</li>
              <li>NICU Nurses (Jr/Sr)</li>
              <li>Paediatrics Registar</li>
              <li>OT Technician</li>
              <li>Radiologist</li>
            </ul>
          </div>
*/}
        </div>
      </div>
    </>
  );
};

export default Careers;
