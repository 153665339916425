import React from "react";
import BreadCrumb from "../BreadCrumb";

const PageHeader = ({ bgImg, title, content, pathname = "" }) => {
  let h1Class = "";
  if (
    pathname != "" &&
    (pathname === "/centre/bangalore" ||
      pathname === "/centre/mysore" ||
      pathname === "/centre/fertility-center")
  ) {
    h1Class = "pinkBackground";
  }

  return (
    <React.Fragment>
      <div
        className="page-header-area bg-img"
        style={{ backgroundImage: `url(${bgImg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-10 col-xl-8 m-auto text-center">
              <div className="page-header-content-inner">
                <div className="page-header-content">
                  {title?.length > 1 && <h1 className={h1Class}>{title}</h1>}

                  <p>{content}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BreadCrumb />
    </React.Fragment>
  );
};

export default PageHeader;
